// eslint-disable-next-line no-unused-vars
import type { NextPage } from 'next'
import LiteLayout from '@@/LiteLayout'
import { useRequiresAuth } from '@coding4tomorrow/c4t-next-core'
import { isSuperiorRole } from '~/types/userRoles'
import Home from '~/containers/Home'

const HomePage: NextPage = () =>
  useRequiresAuth(
    () => (
      <LiteLayout>
        <Home />
      </LiteLayout>
    ),
    (user: any) => isSuperiorRole(user?.type),
    '/login',
  )

export default HomePage
