import EventsLists from '@/Events/EventsLists'
import { useSWR, api } from '@coding4tomorrow/c4t-next-core'
import Spinner from '@@/Spinner'
import CreateFirstEvent from '@/Events/CreateFirstEvent'
import { useState } from 'react'
import CreateEventModal from '@/Events/CreateEventModal'
import Button from '@@/Button'
import H1 from '@@/H1'
import FreeTrialEndedToolTip from '@/FreeTrialEndedToolTip'

const Home = () => {
  const [isModalOpen, isModalOpenSet] = useState(false)
  const {
    data: events,
    isLoading,
    mutate: mutateEvents,
  } = useSWR(api.url('projects.getProjectsByUser'))

  if (isLoading) {
    return (
      <div className="w-full h-[80vh] flex justify-center items-center">
        <Spinner className="text-indigo-600" />
      </div>
    )
  }

  if (!events?.length) {
    return (
      <div className="max-w-[950px] w-full flex items-center justify-center">
        <CreateFirstEvent mutateEvents={mutateEvents} />
      </div>
    )
  }

  return (
    <div className="max-w-[950px] w-full mt-10 rounded-lg">
      <div className="bg-white px-12 py-8">
        <div className="flex justify-between items-center mb-7">
          <H1>Events</H1>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <FreeTrialEndedToolTip>
              <Button
                data-testid="create-event"
                onClick={() => isModalOpenSet(true)}
              >
                Create new Event
              </Button>
            </FreeTrialEndedToolTip>
          </div>
        </div>
        <EventsLists events={events} />
      </div>
      <CreateEventModal
        isOpen={isModalOpen}
        setIsOpen={isModalOpenSet}
        mutateEvents={mutateEvents}
      />
    </div>
  )
}

export default Home
