import { Disclosure } from '@headlessui/react'
import Layout from '@@/Layout'
import tw from 'tailwind-styled-components'
import AccountDropdown from './components/AccountDropdown'
import Link from '@@/Link'
import { useUser } from '@coding4tomorrow/c4t-next-core'
import {
  LiteLayoutProvider,
  useLiteLayoutContext,
} from '~/context/LiteLayoutContext'
import classNames from 'classnames'
import { useEffect } from 'react'

const StyledDisclosure = tw(Disclosure)<any>`
  bg-white
  border-b
  border-gray-200
`
export const useLiteLayoutBackgroundImage = () => {
  const { setLayoutBackgroundStyles } = useLiteLayoutContext()
  useEffect(() => {
    setLayoutBackgroundStyles('bg-global-background bg-cover bg-no-repeat')
    return () => setLayoutBackgroundStyles('')
  }, [setLayoutBackgroundStyles])
}

export const WithLiteLayoutBackgroundImage = <P extends {}>(
  Comp: React.ComponentType<P>,
) =>
  function EnhancedComponent(props: P) {
    useLiteLayoutBackgroundImage()
    return <Comp {...props} />
  }

const LiteLayoutContainer = ({ children }: any) => {
  const { user } = useUser()
  const { layoutBackgroundStyles } = useLiteLayoutContext()

  return (
    <Layout>
      <div
        className={classNames(
          'min-h-screen flex flex-col bg-primary-100',
          layoutBackgroundStyles,
        )}
      >
        <StyledDisclosure as="nav">
          <div className="sticky h-[8vh] flex items-center justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <span className="text-3xl font-bold leading-tight text-indigo-600 cursor-pointer">
                    <Link
                      href={user ? '/' : '/login'}
                      className="text-text-color hover:text-text-color"
                    >
                      GLEAM
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <AccountDropdown />
          </div>
        </StyledDisclosure>
        <main className="min-h-full w-full grow flex flex-col">
          <div className="grow w-full h-full flex justify-center">
            {children}
          </div>
        </main>
      </div>
    </Layout>
  )
}
const LiteLayout = ({ children }: any) => (
  <LiteLayoutProvider>
    <LiteLayoutContainer>{children}</LiteLayoutContainer>
  </LiteLayoutProvider>
)

export default LiteLayout
