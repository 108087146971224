/* eslint-disable @next/next/no-css-tags */
import { createGlobalStyle } from 'styled-components'
import Head from 'next/head'

import global from './styles/global'
import print from './styles/print'

const GlobalStyles = createGlobalStyle`
  ${global};
  ${print};
`

const Layout = ({ children, ...props }: any) => (
  <div {...props}>
    <Head>
      <title>Livedesigners Admin GLEAM</title>
    </Head>
    <GlobalStyles />
    {children}
  </div>
)

export default Layout
