import { createContext, useContext, useState } from 'react'

const initialValues = {
  canPublishEvent: false,
  isCanPublishEventLoading: true,
  mutateCanPublishEvent: null,
}
interface LiteLayoutReturnedType {
  layoutBackgroundStyles: string
  setLayoutBackgroundStyles: (layoutBackgroundStyles: string) => void
}
const SideBareLayoutContext = createContext(initialValues)
const { Provider } = SideBareLayoutContext

const LiteLayout = (): LiteLayoutReturnedType => {
  const [layoutBackgroundStyles, setLayoutBackgroundStyles] = useState('')

  return {
    layoutBackgroundStyles,
    setLayoutBackgroundStyles,
  }
}

export const useLiteLayoutContext = (): LiteLayoutReturnedType =>
  useContext(SideBareLayoutContext) as any

export const LiteLayoutProvider = ({ children }: any) => (
  <Provider value={LiteLayout() as any}>{children}</Provider>
)
