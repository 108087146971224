import Modal from '@@/Modal'
import CreateEventForm from './components/Form'
import H1 from '@@/H1'

interface CreateEventModalTypes {
  isOpen: boolean
  setIsOpen: any
  mutateEvents: () => void
}

const CreateEventModal = ({
  isOpen,
  setIsOpen,
  mutateEvents,
}: CreateEventModalTypes) => (
  <Modal
    isOpen={isOpen}
    setIsOpen={setIsOpen}
  >
    <div className="max-w-[600px] w-full">
      <H1 className="text-left mb-10">Create an event</H1>
      <CreateEventForm
        mutateEvents={mutateEvents}
        setIsOpen={setIsOpen}
      />
    </div>
  </Modal>
)

export default CreateEventModal
