import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useSWR, api } from '@coding4tomorrow/c4t-next-core'
import { useRouter } from 'next/router'
import { StageProps } from '~/types/stage'

const initialValues = {
  events: null,
  mutateEvents: null,
  isLoadingEvents: false,
  stages: null,
  selectedStage: null,
  mutateStages: null,
  isLoadingStages: false,
  canPublishEvent: false,
  isCanPublishEventLoading: true,
  mutateCanPublishEvent: null,
}
interface SideBarLayoutReturnedType {
  events: []
  mutateEvents: () => void
  isLoadingEvents: boolean
  selectedTab: string
  setSelectedTab: (selectedTab: string) => void
  selectedEvent: any
  stages: StageProps[]
  selectedStage: StageProps
  isLoadingStages: boolean
  mutateStages: () => void
  isValidatingStages: boolean
  canPublishEvent: boolean
  isCanPublishEventLoading: boolean
  mutateCanPublishEvent: () => void
  layoutBackgroundStyles: string
  setLayoutBackgroundStyles: (layoutBackgroundStyles: string) => void
}
const SideBareLayoutContext = createContext(initialValues)
const { Provider } = SideBareLayoutContext

const SideBarLayout = (): SideBarLayoutReturnedType => {
  const router = useRouter()
  const [selectedTab, setSelectedTab] = useState('')
  const [layoutBackgroundStyles, setLayoutBackgroundStyles] = useState('')
  const {
    data: events,
    mutate: mutateEvents,
    isLoading: isLoadingEvents,
  } = useSWR(api.url('projects.getProjectsByUser'))

  const {
    data: stages,
    mutate: mutateStages,
    isLoading: isLoadingStages,
    isValidating: isValidatingStages,
  } = useSWR(
    api.url('stages.getStagesByEvent', {
      id: router?.query?.id,
    }),
  )

  const {
    data: canPublishEvent,
    isLoading: isCanPublishEventLoading,
    mutate: mutateCanPublishEvent,
  } = useSWR(
    api.url('projects.canPublishProject', {
      id: router?.query?.id,
    }),
  )

  const selectedEvent = useMemo(
    () => events?.find((event: any) => event._id === router.query.id),
    [events, router],
  )
  const selectedStage = useMemo(
    () =>
      stages?.find((stage: StageProps) => stage._id === router.query.stageId),
    [stages, router],
  )

  return {
    events,
    mutateEvents,
    isLoadingEvents,
    selectedTab,
    setSelectedTab,
    selectedEvent,
    stages,
    selectedStage,
    isLoadingStages,
    mutateStages,
    isValidatingStages,
    canPublishEvent,
    isCanPublishEventLoading,
    mutateCanPublishEvent,
    layoutBackgroundStyles,
    setLayoutBackgroundStyles,
  }
}

export const useSideBarLayoutContext = (): SideBarLayoutReturnedType =>
  useContext(SideBareLayoutContext) as any

export const useHighlighted = (highlighted: string) => {
  const { setSelectedKeys }: any = useSideBarLayoutContext()

  useEffect(() => setSelectedKeys(highlighted), [highlighted, setSelectedKeys])
}

export const SideBarLayoutProvider = ({ children }: any) => (
  <Provider value={SideBarLayout() as any}>{children}</Provider>
)
