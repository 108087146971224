import classNames from 'classnames'
import { useMemo } from 'react'

interface TagProps {
  color?: 'red' | 'green' | 'gray' | 'blue' | string
  textColor?: string
  className?: string
  children: React.ReactNode
  small?: boolean
}
const Tag = ({
  color = 'gray',
  className,
  textColor,
  children,
  small = false,
}: TagProps) => {
  const colorClasses = useMemo(() => {
    if (color === 'red') {
      return 'bg-red-500 text-red-100'
    }
    if (color === 'green') {
      return 'bg-green-100 text-green-700'
    }
    if (color === 'gray') {
      return 'bg-gray-100 text-text-color'
    }
    if (color === 'blue') {
      return 'bg-blue-300 text-blue-800'
    }
    return `bg-[${color}] text-[${textColor}]`
  }, [color, textColor])
  return (
    <span
      className={classNames(
        'rounded-full',
        colorClasses,
        small ? 'px-2 py-1 text-xs' : 'px-4 py-2 text-sm',
        className,
      )}
    >
      {children}
    </span>
  )
}

export default Tag
