import { css } from 'styled-components'

export default css`
  body,
  html {
    height: 100%;
    font-family: @font-family;
    background-color: @white;
  }

  #__next {
    min-height: 100%;
    display: flex;
    flex: auto;
    flex-direction: column;
  }

  /*
   * ANT: prevents form items to have a margin bottom
   * on the last element
   */
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`
