import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'

interface ModalTypes {
  isOpen: boolean
  setIsOpen: any
  title?: string
  children: JSX.Element | string
  isClosable?: boolean
}

const Modal = ({
  isOpen,
  setIsOpen,
  title,
  children,
  isClosable = false,
}: ModalTypes) => (
  <Transition.Root
    show={isOpen}
    as={Fragment}
  >
    <Dialog
      as="div"
      className="relative z-10"
      onClose={isClosable ? () => setIsOpen(false) : () => {}}
      onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative max-w-[50%] bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  {title && (
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-bold text-gray-900 mb-10"
                    >
                      {title}
                    </Dialog.Title>
                  )}

                  {children}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
)

export default Modal
