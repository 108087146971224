export enum EventTypes {
  public = 'public',
  private = 'private',
}

export interface EventProps {
  _id: string
  // eslint-disable-next-line no-restricted-globals
  name: string
  type: EventTypes
  isFinished: boolean
  isActive: boolean
  createdAt: Date
}

export const isPublic = (type: EventTypes) => type === EventTypes.public
export const isPrivate = (type: EventTypes) => type === EventTypes.private

export const eventPrivacyOptions = [
  {
    id: 'private',
    label: 'Private',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 'public',
    label: 'Public',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
]
