/* eslint-disable react/jsx-no-useless-fragment */
import { Tooltip } from 'flowbite-react'
import { useUser } from '@coding4tomorrow/c4t-next-core'
import { isFreeTrialEnded } from '~/types/user'
import { messages } from '~/helpers/messages'
import React from 'react'

interface FreeTrialEndedToolTipProps {
  children: React.ReactNode
}

const ToolTipContent = () => (
  <div className="w-52 z-[1000]">{messages.freeTrialEnded}</div>
)
const FreeTrialEndedToolTip = ({ children }: FreeTrialEndedToolTipProps) => {
  const { user } = useUser()

  if (isFreeTrialEnded(user?.status)) {
    return (
      <Tooltip content={<ToolTipContent />}>
        {React.cloneElement(children as any, { disabled: true })}
      </Tooltip>
    )
  }
  return <>{children}</>
}

export default FreeTrialEndedToolTip
