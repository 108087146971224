/* eslint-disable no-restricted-globals */
/* eslint-disable no-use-before-define */
import { StageProps } from './stage'

export enum UserRoles {
  user = 'user',
  moderator = 'moderator',
}

export enum UserTypes {
  participant = 'participant',
  guest = 'guest',
  broadcaster = 'broadcaster',
}

export const UsersDisplayTypes = {
  ...UserTypes,
  moderator: 'moderator',
}

export enum UserEventTypes {
  TOKEN_EMAIL_SENT = 'token-email-sent',
}

export interface UserEvent {
  value: UserEventTypes
  // eslint-disable-next-line no-restricted-globals
  event?: string
}

export interface UserStage {
  type: UserTypes
  isModerator: boolean
  stage: StageProps
}

export enum AccountStatus {
  FREE_TRIAL = 'free-trial',
  FREE_TRIAL_ENDED = 'free-trial-ended',
  PAY_AS_YOU_GO = 'pay-as-you-go',
}

export const isFreeTrial = (status: AccountStatus) =>
  status === AccountStatus.FREE_TRIAL
export const isFreeTrialEnded = (status: AccountStatus) =>
  status === AccountStatus.FREE_TRIAL_ENDED
export const isPayAsYouGo = (status: AccountStatus) =>
  status === AccountStatus.PAY_AS_YOU_GO

export interface UserProps {
  isModerator: boolean
  _id: string
  firstName: string
  lastName: string
  email: string
  role: UserRoles
  type: UserTypes
  status: AccountStatus
  groups: string[]
  project: {
    eventId: string
  }
  authToken: string
  amount: string
  events: UserEvent[]
  stages: UserStage[]
}

export const isParticipant = (type: UserTypes) => type === UserTypes.participant
export const isGuest = (type: UserTypes) => type === UserTypes.guest
export const isBroadcaster = (type: UserTypes) => type === UserTypes.broadcaster
export const isModerator = (type: keyof typeof UsersDisplayTypes) =>
  type === UsersDisplayTypes.moderator

export enum ManageUsersOptionsValueEnum {
  CREATE_USERS = 'create-users',
  ADD_EXISTING_USERS = 'add-existing-users',
  IMPORT_CSV_FILES = 'import-csv-file',
}
export type ManageUsersOptionsValueType = `${ManageUsersOptionsValueEnum}`
export interface ManageUsersOptionsType {
  // eslint-disable-next-line no-restricted-globals
  name: string
  description: string
  value: `${ManageUsersOptionsValueEnum}`
}
export const ManageUsersOptions: ManageUsersOptionsType[] = [
  {
    name: 'Create users',
    description: 'Ea sunt non voluptate minim officia exercitation.',
    value: 'create-users',
  },
  {
    name: 'Add existing users',
    description:
      'Quis quis pariatur consectetur in laboris exercitation anim anim culpa adipisicing anim.',
    value: 'add-existing-users',
  },
  {
    name: 'Import CSV file',
    description:
      'Fugiat pariatur ut officia non consectetur do ad cupidatat Lorem non voluptate.',
    value: 'import-csv-file',
  },
]

export const UsersTypeDescriptions: Record<string, string> = {
  participant:
    'participant Elit adipisicing ipsum do mollit elit commodo commodo.Anim et dolor adipisicing enim tempor duis.Cupidatat minim laboris non irure veniam amet exercitation ea et irure mollit sunt.participant Elit adipisicing ipsum do mollit elit commodo commodo.Anim et dolor adipisicing enim tempor duis.Cupidatat minim laboris non irure veniam amet exercitation ea et irure mollit sunt.',
  broadcaster:
    'broadcaster Elit adipisicing ipsum do mollit elit commodo commodo.Anim et dolor adipisicing enim tempor duis.Cupidatat minim laboris non irure veniam amet exercitation ea et irure mollit sunt.participant Elit adipisicing ipsum do mollit elit commodo commodo.Anim et dolor adipisicing enim tempor duis.Cupidatat minim laboris non irure veniam amet exercitation ea et irure mollit sunt.',
  guest:
    'guest Elit adipisicing ipsum do mollit elit commodo commodo.Anim et dolor adipisicing enim tempor duis.Cupidatat minim laboris non irure veniam amet exercitation ea et irure mollit sunt.participant Elit adipisicing ipsum do mollit elit commodo commodo.Anim et dolor adipisicing enim tempor duis.Cupidatat minim laboris non irure veniam amet exercitation ea et irure mollit sunt.',
  moderator:
    'moderator Elit adipisicing ipsum do mollit elit commodo commodo.Anim et dolor adipisicing enim tempor duis.Cupidatat minim laboris non irure veniam amet exercitation ea et irure mollit sunt.participant Elit adipisicing ipsum do mollit elit commodo commodo.Anim et dolor adipisicing enim tempor duis.Cupidatat minim laboris non irure veniam amet exercitation ea et irure mollit sunt.',
}
