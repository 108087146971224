import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { useRouter } from 'next/router'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { EventProps } from '~/types/event'
import Button from '@@/Button'
import Tag from '@@/Tag'
import Table from '@@/Table'
import { capitalizeFirstLetter } from '~/helpers/capitalize-first-letter'

interface EventsListsProps {
  events: EventProps[]
}

const columns = [
  { key: 'Event name', value: 'Event name' },
  { key: 'Created at', value: 'Created at' },
  { key: 'Type', value: 'Type' },
  { key: 'Status', value: 'Status' },
  { key: 'Actions', value: 'Actions', isAction: true },
]

const EventsLists = ({ events }: EventsListsProps) => {
  const router = useRouter()

  const eventStatus = useCallback((event: EventProps) => {
    if (event.isFinished) {
      return 'Finished'
    }

    if (event.isActive) {
      return 'Published'
    }

    return 'Unpublished'
  }, [])

  const eventStatusColor = useCallback((event: EventProps) => {
    if (event.isFinished) {
      return 'red'
    }
    if (event.isActive) {
      return 'green'
    }
    return 'gray'
  }, [])

  const handleRowClick = useCallback(
    (data: EventProps) => router.push(`/events/${data._id}/update`),
    [router],
  )

  const rows = useMemo(
    () =>
      events.map((event: EventProps) => ({
        key: event._id,
        data: event,
        value: [
          event.name,
          moment(event.createdAt).format('MMMM Do YYYY, h:mm:ss a'),
          capitalizeFirstLetter(event.type),
          // eslint-disable-next-line react/jsx-key
          <Tag color={eventStatusColor(event)}>{eventStatus(event)}</Tag>,
          // eslint-disable-next-line react/jsx-key
          <Button
            href={`/events/${event._id}/update`}
            leftIcon={<PencilSquareIcon className="w-4 h-4" />}
            buttonType="outlined"
            small
          >
            Edit
          </Button>,
        ],
      })),
    [eventStatus, eventStatusColor, events],
  )

  return (
    <Table
      columns={columns}
      rows={rows}
      pageLength={10}
      onRowClick={handleRowClick}
    />
  )
}

export default EventsLists
