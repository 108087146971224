/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames'
import { Pagination } from 'flowbite-react'
import { useEffect, useMemo, useState } from 'react'

interface ColumnType {
  key: string
  value: React.ReactNode | string
  isAction?: boolean
}
interface RowType {
  key: string
  data: any
  value: (React.ReactNode | string)[]
  hasAction?: boolean
}
interface TableProps {
  columns: ColumnType[]
  rows: RowType[]
  onRowClick?: (data: any) => void
  pageLength?: number
  emptyState?: React.ReactNode
  paginationPlacement?: 'start' | 'end' | 'center'
}
const Table = ({
  columns,
  rows,
  onRowClick,
  pageLength = 1,
  emptyState,
  paginationPlacement = 'end',
}: TableProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const totalPages = useMemo(() => {
    if (!rows) {
      return 0
    }
    return Math.ceil(rows.length / pageLength)
  }, [rows, pageLength])

  useEffect(() => {
    if (totalPages) {
      setCurrentPage(1)
    }
  }, [totalPages])

  const currentPageRows = useMemo(() => {
    if (!rows.length) {
      return []
    }
    const currentUsers = []
    const startingIndex = pageLength * (currentPage - 1)
    const endIndex = startingIndex + pageLength

    for (let i = startingIndex; i < endIndex; i += 1) {
      if (rows[i]) {
        currentUsers.push(rows[i])
      }
    }
    return currentUsers
  }, [rows, currentPage, pageLength])

  const actionsColumnsIndexes = useMemo(
    () =>
      columns.reduce((acc: number[], cur, index) => {
        if (cur.isAction) {
          acc.push(index)
        }
        return acc
      }, []),
    [columns],
  )

  return (
    <>
      <table className="min-w-full divide-y divide-gray-100">
        <thead className="bg-gray-100 text-sm font-normal text-neutral-800 text-left">
          <tr>
            {columns
              ?.filter((item) => !!item)
              ?.map((column) => (
                <th
                  scope="col"
                  className="px-3 py-4"
                  key={column.key}
                >
                  {column.value}
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white text-xs font-normal text-table-col-text whitespace-nowrap">
          {rows.length > 0 ? (
            currentPageRows
              ?.filter((item) => !!item)
              ?.map((row) => (
                <tr
                  key={row.key}
                  className="hover:bg-gray-100 cursor-pointer"
                >
                  {row.value?.map((data, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <td
                      key={Math.random()}
                      onClick={() =>
                        !actionsColumnsIndexes.includes(index)
                          ? onRowClick?.(row.data)
                          : null
                      }
                      className=" py-4 px-3"
                      aria-hidden="true"
                    >
                      {data}
                    </td>
                  ))}
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan={7}>
                <div className="m-4 relative block rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  {emptyState}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {totalPages > 1 && (
        <div
          className={classNames('w-full flex', {
            'justify-end': paginationPlacement === 'end',
            'justify-start': paginationPlacement === 'start',
            'justify-center': paginationPlacement === 'center',
          })}
        >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(value) => setCurrentPage(value)}
            showIcons
          />
        </div>
      )}
    </>
  )
}

export default Table
