import classNames from 'classnames'

interface PProps {
  children?: React.ReactNode
  className?: string
}

const P = ({ children, className }: PProps) => (
  <p
    className={classNames(
      'text-base font-normal text-text-color leading-6',
      className,
    )}
  >
    {children}
  </p>
)

export default P
