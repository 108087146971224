import Button from '@@/Button'
import Image from 'next/image'
import P from '@@/P'
import H1 from '@@/H1'

interface InformationTemplateProps {
  imageLink: string
  title: string | React.ReactNode
  description: string | React.ReactNode
  buttonContent?: string | React.ReactNode
  onButtonClick?: () => void
}
const InformationTemplate = ({
  imageLink = '/illustrations/visual-create-event-page.svg',
  title,
  description,
  buttonContent,
  onButtonClick,
}: InformationTemplateProps) => (
  <div className="w-full max-w-[950px] bg-white rounded-lg backdrop-blur-[2px] m-auto flex flex-col items-center justify-center px-16 py-8">
    <Image
      src={imageLink}
      width={416}
      height={236}
      alt="visual-create-event-page"
    />
    <H1 className="mt-10 mx-36 text-center">{title}</H1>
    <P className="mt-4 mx-20">{description}</P>
    <div className="flex gap-3">
      {buttonContent && (
        <Button
          onClick={onButtonClick}
          data-testid="create-first-event-button"
          className="my-4"
        >
          {buttonContent}
        </Button>
      )}
    </div>
  </div>
)

export default InformationTemplate
